<template>
  <div v-if="visible">
    <el-dialog v-ismove :visible="true" :title="formData.roleId ? '编辑角色' : '创建角色'"
      width="600px" :before-close="closeTab" :close-on-click-modal="false">
      <el-form ref="formData" :model="formData" :rules="rules" inline size="small" label-width="100px">
        <el-form-item label="上级角色" prop="supRoleId">
          <!-- 只有两层角色 所以上级角色下拉列表只能是一级 -->
          <el-select v-model="formData.supRoleId" clearable>
            <el-option v-for="item in supRoleIdList" :key="item.roleId" :label="item.roleName" :value="item.roleId" :disabled="item.roleId === formData.roleId"></el-option>
          </el-select>
          <!-- <el-cascader :options="roleList" :props="defaultProps" style="width: 430px" v-model="supRoleIdList" clearable placeholder="默认是顶级角色"></el-cascader> -->
        </el-form-item>
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="formData.roleName" style="width: 430px" clearable placeholder="请输入角色名称"></el-input>
        </el-form-item>
        <el-row>
          <el-form-item label="分配给人员">
            <div style="min-height: 30px; width: 430px;border: 1px solid #dcdfe6;
              border-radius: 4px;cursor: pointer; line-height: 30px;" @click="selectUser">
              <span v-if="userList.length===0" style="margin-left: 15px; color: #b2b2b2;">请选择人员</span>
              <el-tag v-for="(item, index) in userList" :key="item.userId"
                closable @close="removeUser(index)" style="margin-left:5px;height:22px;line-height:22px;">
                {{ item.name }}({{ item.username }})
              </el-tag>
            </div>
          </el-form-item>
        </el-row>
        <!-- <el-form-item label="是否启用" prop="status">
          <el-switch v-model="formData.status" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
        </el-form-item> -->
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="closeTab">取消</el-button>
        <el-button type="primary" size="small" @click="submit" :loading="waiting">确定</el-button>
      </div>
    </el-dialog>
    <!-- 人员选择 -->
    <user-conf v-bind.sync="selectObj" @save="saveUsre"></user-conf>
  </div>
</template>
<script>
import { deepClone } from '@/utils/index'
import * as validFn from '@/utils/validate'
import userConf from '@/components/DepartmentPerson'
import { systemRoleAdd, systemRoleUpdate } from '@/api/systemRole'
export default {
  components: { userConf },
  data() {
    return {
      formData: {
        supRoleId: '',
        roleName: '',
        userIds: []
        // status: 1
      },
      rules: {
        roleName: [validFn.required()],
        userIds: [validFn.requiredC()]
      },
      systemList: [],
      selectObj: {
        visible: false,
        allUser: []
        // departTree: []
      },
      waiting: false,
      userList: []
      // departTree: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    roleItemData: {
      type: Object,
      default: null
    },
    supRoleIdList: {
      type: Array,
      default: () => []
    },
    allUser: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.waiting = false
        if (this.roleItemData) {
          // Object.assign(this.formData, this.roleItemData)
          this.formData.roleName = this.roleItemData.roleName
          this.formData.supRoleId = this.roleItemData.supRoleId === -1 ? '' : this.roleItemData.supRoleId
          this.formData.userIds = this.roleItemData.userIds
          // this.formData.status = this.roleItemData.status
          this.formData.roleId = this.roleItemData.roleId
          if (this.roleItemData.userIds && this.roleItemData.userIds.length > 0) {
            this.userList = this.allUser.filter(item => { return this.roleItemData.userIds.includes(String(item.userId)) })
          } else {
            this.userList = []
          }
        }
      }
    }
  },
  methods: {
    closeTab() {
      this.waiting = false
      this.$refs.formData.resetFields()
      this.formData = {
        supRoleId: '',
        roleName: '',
        userIds: [],
        status: '1'
      }
      this.userList = []
      this.$emit('update:visible', false)
    },
    submit() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          if (this.userList.length > 0) {
            this.formData.userIds = this.userList.map(item => item.userId)
          } else {
            this.formData.userIds = []
          }
          this.formData.supRoleId = this.formData.supRoleId ? this.formData.supRoleId : -1
          if (this.formData.roleId) {
            const params = deepClone(this.formData)
            params.roleId = this.formData.roleId
            this.waiting = true
            systemRoleUpdate(params).then(res => {
              if (res.code === '000000') {
                this.$refs.formData.resetFields()
                this.$emit('success')
                this.$message.success(res.message)
              }
              this.waiting = false
            }).catch(() => {
              this.waiting = false
            })
          } else {
            this.waiting = true
            systemRoleAdd(this.formData).then(res => {
              if (res.code === '000000') {
                this.$refs.formData.resetFields()
                this.$emit('success')
                this.$message.success(res.message)
              }
              this.waiting = false
            }).catch(() => {
              this.waiting = false
            })
          }
        } else {
          return false
        }
      })
    },
    removeUser(index) {
      this.userList.splice(index, 1)
    },
    selectUser() {
      this.selectObj = {
        visible: true,
        allUser: this.allUser,
        // departTree: this.departTree,
        hasChoosed: this.userList // 已选择的
      }
    },
    saveUsre(params) {
      this.selectObj.visible = false
      this.userList = deepClone(params)
    }
  }
}
</script>

