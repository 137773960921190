<template>
  <div class="app-container">
    <el-row class="clearfix">
      <el-form :model="searchForm" inline size="small" class="floatL">
        <el-form-item label="角色名称">
          <el-input v-model="searchForm.roleName" clearable placeholder="角色名称"></el-input>
        </el-form-item>
        <el-form-item label="分配给人员">
          <el-select v-model="searchForm.username" clearable size="small"
            remote :remote-method="filterUser" filterable placeholder="请先输入后选择人员">
            <el-option v-for="item in userList" :key="item.userId" :label="`${item.name}(${item.username})`" :value="item.username"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="拥有系统">
          <el-select v-model="searchForm.usName" placeholder="选择系统" :collapse-tags="true" clearable filterable>
            <el-option v-for="item in allSystem" :key="item.usId" :value="item.usName" :label="item.usName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchForm.status" clearable placeholder="选择状态">
            <el-option :value="1" label="启用"></el-option>
            <el-option :value="0" label="禁用"></el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" size="small" @click="search">查询</el-button>
      </el-form>
      <div class="floatR">
        <el-button type="primary" plain size="small" icon="el-icon-menu" @click="show=true">查看角色组织架构</el-button>
        <el-button type="primary" size="small" icon="el-icon-circle-plus-outline" @click="roleOpt(null)">添加角色权限</el-button>
      </div>
    </el-row>
    <div class="top-tips">tips：优先将员工分配到对应角色，给此角色分配系统后再赋予权限。若此角色里只有某1个或者N个员工需要额外增加权限，请在【账号管理】或【权限分配】内单独赋予私人权限。</div>
    <el-table v-loading="loading" :data="dataList" border size="small" :header-cell-style="{background: '#41A1FF', color:'#fff'}">
      <el-table-column label="角色名称" prop="roleName" width="140" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column label="角色编码" prop="roleId" width="120" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column label="分配给人员" prop="personList" width="260" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.userName && scope.row.userName.join('、') }}
        </template>
      </el-table-column>
      <el-table-column label="拥有系统" prop="systems" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-for="item in scope.row.userSystemRoleResList" value-key="usId" :key="item.usId">{{item.usName}}、</span>
        </template>
      </el-table-column>
      <el-table-column label="创建人" prop="createBy" width="130" align="center"></el-table-column>
      <el-table-column label="创建时间" prop="createTime" width="160" align="center"></el-table-column>
      <el-table-column label="状态" prop="status" width="120" align="center">
        <template slot-scope="scope">
          <el-tooltip effect="dark" :content="scope.row.status==='0' ? '禁用' : '启用'" placement="top">
            <el-switch v-model="scope.row.status" size="mini"
              :active-value="1" :inactive-value="0"
              active-color="#13ce66" inactive-color="#ff4949"
              @change="changeStatus($event, scope.row)">
            </el-switch>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" align="center">
        <template slot-scope="scope">
          <el-tooltip effect="dark" content="编辑" placement="top">
            <i class="table_icons el-icon-edit" @click="roleOpt(scope.row)"></i>
          </el-tooltip>
          <el-tooltip effect="dark" content="删除" placement="top">
            <i class="table_icons el-icon-delete" @click="delNode(scope.row.roleId)"></i>
          </el-tooltip>
          <el-tooltip effect="dark" content="分配系统" placement="top">
            <svg-icon icon-class="allot" @click="confSystem(scope.row)" style="width: 16px; height: 16px; cursor:pointer; margin-right:10px"></svg-icon>
          </el-tooltip>
          <el-tooltip effect="dark" content="授权" placement="top">
            <svg-icon icon-class="empower" style="width: 16px; height: 16px; cursor:pointer;" @click="empower(scope.row)"></svg-icon>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top:1em; text-align:center">
      <el-pagination
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :current-page="page"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange">
      </el-pagination>
    </div>
    <!-- 角色架构 -->
    <el-dialog v-ismove :visible="show" title="角色组织架构" width="600px" :close-on-click-modal="false" @close="show=false">
      <div style="height: 500px;overflow-y:auto;">
        <el-input
          size="small"
          placeholder="输入关键字进行过滤"
          style="margin-bottom: 8px;"
          v-model="filterText">
        </el-input>
        <el-tree
          class="filter-tree"
          :data="rolesTree"
          :props="defaultProps"
          default-expand-all
          :filter-node-method="filterNode"
          icon-class="el-icon-d-arrow-right"
          ref="tree">
        </el-tree>
      </div>
    </el-dialog>
    <!-- 新增编辑角色 -->
    <role-panel v-bind.sync="optionsObj" @success="success"></role-panel>
    <!-- 分配系统 -->
    <sys-panel v-bind.sync="sysObj" @success="success"></sys-panel>
    <!-- 授权 -->
    <power-conf v-bind.sync="powerObj" @success="success"></power-conf>
  </div>
</template>
<script>
import rolePanel from './addPanel'
import powerConf from '@/components/powerConf'
// 分配系统，后续抽取到components组件中
import sysPanel from '../userAuthorization/systemConf'
import { getAuthorizationSystem } from '@/api/account'
import { userSystemMap } from '@/api/system-list'
// import { getDDusers } from '@/api/role'
import { getSystemRolePage, systemRoleDelete, getRoleSupInfo, systemRoleStatusUpdate, getAllSystemList } from '@/api/systemRole'
export default {
  components: { rolePanel, powerConf, sysPanel },
  data() {
    return {
      searchForm: {
        username: '',
        usName:'',
        roleName: '',
        status: ''
      },
      systemList: [],
      loading: false,
      dataList: [],
      pageSize: 10,
      page: 1,
      total: 0,
      show: false,
      rolesTree: [],
      defaultProps: {
        label: 'roleName',
        children: 'supRoleList'
      },
      filterText: '',
      // allUser: [],
      optionsObj: {
        visible: false,
        roleItemData: null
      },
      powerObj: {
        visible: false,
        userId: null,
        roleId: null,
        systemList: []
      },
      sysObj: {
        visible: false,
        systemList: [],
        ids: [],
        roleId: null
      },
      userList: [],
      allSystem: []
    }
  },
  computed: {
    allUser() {
      return this.$store.getters.ddAllUser
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  mounted() {
    this.search()
    this.getPowers()
    this.getRoleSupList()
    this.getAllSystem()
    // 获取人员列表 后续用VUX
    // this.getPerson(0)
    if (this.allUser && this.allUser.length < 1) {
      this.$store.dispatch('GetDdAllUser')
    }
  },
  methods: {
    // 获取全部系统
    getAllSystem() {
      getAllSystemList().then(res => {
          this.allSystem = res.data
      }).catch(error => {
        console.log(error)
      })
    },
    // 获取员工 0为全部数据
    // getPerson(id) {
    //   this.allUser = []
    //   getDDusers(id).then(res => {
    //     if (res.code === '000000') {
    //       this.allUser = res.data
    //     }
    //   }).catch()
    // },
    // 获取角色架构
    getRoleSupList() {
      getRoleSupInfo().then(res => {
        if (res.code === '000000') {
          this.rolesTree = res.data
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 登录用户被指派的系统(超管则是全部系统)
    getPowers() {
      this.systemList = []
      if (this.$store.getters.roles.userInfo.roleId === 1) {
        userSystemMap().then(res => {
          if (res.code === '000000') {
            this.systemList = res.data
          }
        }).catch(error => {
          console.log(error)
        })
      } else {
        getAuthorizationSystem(this.$store.getters.roles.userInfo.userId).then(res => {
          if (res.code === '000000') {
            this.systemList = res.data
          }
        }).catch()
      }
    },
    getData() {
      const params = Object.assign({}, this.searchForm, { pageNum: this.page, pageSize: this.pageSize })
      this.loading = true
      getSystemRolePage(params).then(res => {
        console.log(res)
        if (res.code === '000000' && res.data && res.data.list) {
          this.dataList = res.data.list
          this.total = res.data.total
        } else {
          this.dataList = []
          this.total = 0
        }
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.dataList = []
        this.total = 0
        this.loading = false
      })
    },
    handleCurrentChange(page) {
      this.page = page
      this.getData()
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.getData()
    },
    search() {
      console.log(this.rolesTree)
      if (this.page === 1) {
        this.getData()
      } else {
        this.handleCurrentChange(1)
      }
    },
    roleOpt(row) {
      this.optionsObj = {
        visible: true,
        roleItemData: row || null,
        supRoleIdList: this.rolesTree.map(item => item),
        allUser: this.allUser
      }
    },
    delNode(id) {
      this.$confirm('确定删除该条数据？', '提示', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        systemRoleDelete(id).then(res => {
          this.$message.success(res.message)
          this.getData()
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.$message.info('已取消！')
      })
    },
    changeStatus(val, item) {
      const str = val === 1 ? '确定启用该角色权限？' : '确定禁用该角色权限？'
      this.$confirm(str, '提示', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        // 发送请求，成功后改变状态或拉取数据
        systemRoleStatusUpdate({ roleId: item.roleId, status: val }).then(res => {
          this.$message.success(res.message)
          this.getData()
        }).catch(err => {
          console.log(err)
          item.status = val === 0 ? 1 : 0
        })
      }).catch(() => {
        console.log(this.dataList)
        item.status = val === 0 ? 1 : 0
      })
    },
    filterNode(value, data) {
      if (!value) return true
      return data.roleName.indexOf(value) !== -1
    },
    // 分配系统
    confSystem(item) {
      this.sysObj = {
        type: 'role',
        visible: true,
        Id: item.roleId,
        systemList: this.systemList,
        ids: item.userSystemRoleResList && item.userSystemRoleResList.length > 0 ? item.userSystemRoleResList.map(item => item.usId) : []
      }
    },
    // 授权
    empower(item) {
      this.powerObj = {
        visible: true,
        roleId: item.roleId,
        systemList: item.userSystemRoleResList && item.userSystemRoleResList.length > 0 ? item.userSystemRoleResList : []
      }
    },
    success() {
      this.optionsObj.visible = false
      this.sysObj.visible = false
      this.powerObj.visible = false
      this.getData()
    },
    filterUser(val) {
      if (val) {
        this.userList = this.allUser.filter(item => item.name.indexOf(val.trim()) > -1)
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.top-tips {
  line-height: 30px;
  font-size: 14px;
  color: #F56C6C;
  font-weight: 600;
}
</style>